import React from 'react';

import { PageHeader, PageTitle } from 'components';

export class PrivacyPolicy extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <section>
        <PageHeader>
          <PageTitle>Privacy Policy</PageTitle>
        </PageHeader>
        <div className="wrapper formatted s-bottom--lrg">
          <h1>TeamMak Pty Ltd Privacy &amp; Security Policies</h1>
          <p>
            TeamMak recognises that your privacy is very important to you. We
            are committed to protecting the privacy of your Personal Information
            in accordance with the Privacy Act 1988 (Commonwealth).
          </p>

          <p>
            This Policy describes how TeamMak handles personal information
            collected in Australia, collects, holds, uses and discloses personal
            information consistent with the Privacy Act 1988 (Commonwealth).
          </p>

          <p>
            By providing personal information to TeamMak, and by having access
            to this Policy, a TeamMak-affiliated Independent Business Owner
            (IBO) consents to TeamMak collecting, holding, using and disclosing
            personal information in accordance with the Policy.
          </p>

          <h2>DEFINITIONS</h2>

          <p>In this Privacy Policy:</p>
          <p>
            'Our', 'Us' and 'We' refers to TeamMak Pty Ltd, ABN 19 104 429 905
            (Postal Address: PO Box 7154, Hawthorn, Victoria 3122, Australia).
          </p>
          <p>
            'Personal Information' means any information or an opinion that can
            identify an individual. It is still personal information whether or
            not it is true.
          </p>
          <p>
            'You' refers to a TeamMak-affiliated Amway Independent Business
            Owner (IBO). It also generally refers to a subscriber of the TeamMak
            Website or TeamMak App.
          </p>

          <h2>COLLECTION OF PERSONAL INFORMATION</h2>
          <p>
            As outlined in this Policy, we collect personal information only if
            you are a TeamMak-affiliated Independent Business Owner (IBO).
          </p>
          <p>
            The types of Personal Information that we may collect from you and
            how we use it will depend on the TeamMak-supplied products, services
            and facilities that you use, and how you use them.
          </p>
          <h2>WHAT PERSONAL INFORMATION IS COLLECTED?</h2>
          <p>
            Personal information collected by TeamMak may include your first,
            middle and last names; Amway IBO number; postal address; delivery
            address; email address; telephone number/s; gender; date of birth
            etc.
          </p>
          <p>
            If you do not provide this information, we may not be able to
            process your application for registration to use the TeamMak Website
            or TeamMak App, or provide you with any product or services offered
            in connection with other TeamMak-supplied business support materials
            (BSM).
          </p>
          <h2>HOW WE COLLECT AND HOLD PERSONAL INFORMATION</h2>
          <p>
            Wherever possible we collect personal information directly from you.
            We may collect your personal information via the website, by
            telephone, email, or when you purchase an event registration/ticket
            or some other BSM.
          </p>
          <p>
            There may, however, be occasions where we collect your personal
            information from someone else. For example, where appropriate, from
            Amway of Australia/NZ, or where it is provided to us by a third
            party with your consent.
          </p>
          <p>
            We are committed to keeping your personal information secure and
            will use all reasonable precautions to protect it from interference,
            loss, misuse or unauthorised modification, including:
          </p>
          <ul>
            <li>Securing external and internal premises;</li>
            <li>
              Restricting access to your personal information to TeamMak
              employees, or those who perform services on our behalf who are
              authorised to handle your personal information and on a 'need to
              know' basis;
            </li>
            <li>
              Entering into confidentiality agreements with relevant employees
              and third parties; ;
            </li>
            <li>
              Adopting a Personal and Confidential Information Policy for
              employees;
            </li>
            <li> Appropriate training of staff;</li>
            <li>
              Retaining your personal information for no longer than it is
              reasonably required to meet the purposes described in this policy;
            </li>
            <li>
              Taking reasonable steps to destroy or de-identify personal
              information that is no longer required.
            </li>
          </ul>

          <h2>WHY WE COLLECT AND HOW WE USE PERSONAL INFORMATION</h2>
          <p>
            We generally collect personal information to assist in the provision
            of the various BSM offered to TeamMak-affiliated IBOs. More
            specifically, we collect and use personal information in order to
            process TeamMak Website or TeamMak App subscription applications,
            purchases of meeting, seminar, conference or event
            registrations/tickets, to confirm identification etc, as well as the
            information necessary to calculate and process profit sharing
            payments and refunds where appropriate.
          </p>
          <p>
            If we do not collect your personal information, or if you provide us
            with inaccurate or incomplete information, we may not be able to
            provide you with the product or service, or fulfil any other
            purposes for which we collect personal information.
          </p>
          <p>
            Your personal information may also be used by us for purposes such
            as marketing and analysis.
          </p>
          <p>
            From time to time we may use your personal information to provide
            you with marketing information about the products and services that
            are offered by TeamMak.
            {/* If you do not wish to receive this
            information, you can unsubscribe by
            <Link className="t-secondary t-bold" to="/">
             <span> clicking here</span>
            </Link> */}
          </p>
          <p>
            You will be reminded of your option to opt out of receiving direct
            marketing materials each time you receive direct marketing
            communications from us.
          </p>
          <h2>STORED PAYMENT DETAILS AND PERSONAL INFORMATION</h2>
          <p>
            If you make a payment online via the TeamMak Website/TeamMak App, or
            via EFTPOS for subscriptions, BSM or meeting, seminar, conference or
            event registrations/tickets, your credit card details are not
            retained by TeamMak or by its contracted payment gateway. In
            addition, the payment gateway used by TeamMak to process online/
            EFTPOS payments has industry standard security and is PCI compliant.
          </p>
          <p>
            Where payments for meeting, seminar, conference or event
            registrations/tickets are made using a hard copy order form, and
            include credit card details (such as for conference early bird
            registrations), such order forms are securely held and stored by
            TeamMak for a five-year period as required by the Australian Tax
            Office (ATO). After this time all hard copy order forms are
            destroyed with industry standard certification.
          </p>
          <p>
            TeamMak will take all reasonable steps to protect the security of
            the Personal Information it collects about you.
          </p>

          <h2>DISCLOSURE OF YOUR PERSONAL INFORMATION</h2>
          <p>
            We will never sell, rent or trade your personal information. We may
            disclose your personal information to authorised third-party service
            providers.
          </p>
          <p>
            For example, we may disclose your personal information to authorised
            third party service providers to perform activities in connection
            with the purposes described in this policy. These disclosures
            include:
          </p>
          <ul>
            <li>
              where a product or service you wish to acquire is provided or
              supplied by or through a third party;
            </li>
            <li>
              where a third party, such as a mailing house, carries out
              activities on our behalf;
            </li>
            <li>
              if disclosure is otherwise required in order to provide you with a
              particular product or service;
            </li>
            <li>
              in order to ensure our ability to continue providing products and
              services;
            </li>
            <li>
              if disclosure is required for the purposes of conducting business
              analysis in order to improve our products and services;
            </li>
            <li>if disclosure is required by law; or</li>
            <li>
              where you have provided your consent, which may be given
              expressly, or may reasonably be implied by your conduct.
            </li>
          </ul>
          <p>
            We require related and unrelated third parties to whom your personal
            information is disclosed to keep the information confidential and
            only use it for the same purposes we are permitted to use it.
          </p>
          <p>
            Some of the organisations to which TeamMak may disclose personal
            information are located outside Australia. These organisations may
            for example include entities or suppliers in New Zealand, the United
            States and Canada. We will take reasonable steps to ensure that all
            overseas recipients of personal information comply with the
            Australian Privacy Principles.
          </p>
          <h2>UNSUBSCRIBING FROM MARKETING EMAILS</h2>
          <p>
            From time to time TeamMak may send you meeting, seminar, conference
            or event and other business information to keep you informed and
            help you succeed in your business. To unsubscribe from marketing
            emails{' '}
            <a className="t-secondary t-bold" href="mailto:admin@teammak.com">
              click here
            </a>
            .
          </p>

          <h2> ACCESS TO YOUR PERSONAL INFORMATION</h2>
          <p>
            You may access the information we hold about you. This right is
            subject to the exceptions detailed in the Australian Privacy
            Principles.
          </p>
          <p>
            You may access your Personal Information at no cost to confirm,
            correct, amend or update your Personal Information by visiting the
            appropriate section of the TeamMak Website or TeamMak App.
          </p>
          <p>
            You may also access a summary of your Personal Information by
            contacting our Privacy Officer{' '}
            <a className="t-secondary t-bold" href="mailto:admin@teammak.com">
              click here
            </a>
            .
          </p>
          <p>
            For complex or more detailed requests for access to your Personal
            Information, for example, access to information that is archived, we
            may require you to place your request in writing and charge you a
            fee for our reasonable costs of retrieving and supplying the
            information to you. In all cases you will be asked to verify who you
            are before your Personal Information is provided.
          </p>
          <p>
            All requests to access Personal Information will be handled in a
            reasonable time. If we deny you access, we will let you know why in
            writing.
          </p>

          <h2>CORRECTION OF YOUR PERSONAL INFORMATION</h2>

          <p>
            During the course of your relationship with us we will ask you to
            inform us of any changes to your Personal Information. You may
            update your Personal Information or advise us that the information
            we have is not accurate, complete or up to date by contacting our
            Privacy Officer{' '}
            <a className="t-secondary t-bold" href="mailto:admin@teammak.com">
              click here
            </a>
            .
          </p>
          <p>
            There may be circumstances in which we can refuse to correct
            personal information. If we do so, we will provide a written notice
            providing reasons for the refusal. You will have an opportunity to
            make a formal complaint if you feel it necessary.
          </p>
          <p>
            All requests to correct Personal Information will be handled in a
            reasonable time.
          </p>

          <h2>COMPLAINTS</h2>
          <p>
            You have a right to complain about how your personal information has
            been handled. If you are concerned about a breach of your privacy
            and wish to make a complaint, please provide a written complaint to
            us at:
          </p>
          <p>
            Postal Address: PO Box 7154, Hawthorn, Victoria 3122, Australia{' '}
            <br /> Email: admin@teammak.com
          </p>
          <p>
            TeamMak will respond in writing within 30 days of receipt of a
            complaint.
          </p>

          <h2>CURRENCY AND THE STATUS OF OUR PRIVACY POLICY</h2>
          <p>
            This policy may change from time to time and all changes will be
            posted on the TeamMak Website/App. Whenever you use our website or
            app you should read this Privacy Policy in conjunction with the
            General Terms &amp; Conditions. An individual who provides personal
            information to TeamMak is given access to this Policy.
          </p>
        </div>
      </section>
    );
  }
}
