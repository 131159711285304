import React from 'react';
import { Link } from 'react-router-dom';

import { PageHeader, PageTitle } from 'components';

export class TermsAndConditions extends React.Component {
  ticketingTermsAndConditionsRef = React.createRef<HTMLHeadingElement>();
  componentDidMount() {
    if (window.location.hash === '#ticketing') {
      const id = window.location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
        });
      }
      return;
    }
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <section className="terms">
        <PageHeader>
          <PageTitle>Terms & Conditions</PageTitle>
        </PageHeader>
        <div className="wrapper formatted s-bottom--lrg">
          <h1>TeamMak General Terms &amp; Conditions</h1>
          <h2>
            Acknowledgement and Acceptance of Terms of Use for the TeamMak
            Website and TeamMak App
          </h2>
          <p>
            You should read and familiarise yourself with these Terms of Use
            before you use this website or app. By using this website or app,
            you expressly agree to these Terms of Use as they are amended from
            time to time, and you acknowledge that you have read and understood
            them. You also agree that any information that you provide to us
            will be true, accurate, current and complete and that you will
            maintain and properly update your personal profile as required. You
            should always check this page prior to using the website or the app
            to make sure that you have a current understanding of the Terms of
            Use. If you do not agree with these Terms, you must discontinue your
            use of the website or app.
          </p>
          <h2>Disclaimer and Limitation of Liability</h2>
          <p>
            The information, materials and services contained on this website
            and app are provided to you "as is," and "as available". We do not
            warrant or guarantee the accuracy, adequacy or completeness of this
            information and materials, and expressly disclaim liability for any
            errors and/or omissions in this information and materials. To the
            maximum extent permitted by law, unless otherwise specified, we
            disclaim all representations and warranties of any kind, whether
            express, implied or statutory. In addition, we do not warrant,
            guarantee or make any representations regarding the security of
            accounts, or that this website or app is free from destructive
            materials, including but not limited to computer viruses, hackers,
            or other technical sabotage, nor do we warrant, guarantee or make
            any representations that access to this website and the app will be
            fully accessible at all times, uninterrupted, or error-free. To the
            maximum extent permitted by law, we disclaim liability for any
            damages, including, without limitation, direct or indirect, special,
            incidental, compensatory, exemplary or consequential damages, losses
            or expenses, including without limitation lost or misdirected
            orders, lost profits, lost registrations, lost goodwill, or lost or
            stolen programs or other data, however caused and under any theory
            of liability arising out of or in connection with (1) use of either
            this website or the app, or the inability to use this website or app
            by any party; or (2) any failure or performance, error, omission,
            interruption, defect, delay in operation or transmission; or (3)
            line or system failure or the introduction of a computer virus, or
            other technical sabotage, even if we or our employees or
            representatives are advised of the possibility or likelihood of such
            damages, losses or expenses.
          </p>
          <h2>Intellectual Property</h2>
          <h3>Copyright in Material on this Website and App</h3>
          <p>
            Unless otherwise indicated, all the TeamMak website and TeamMak App
            design, text and graphics are copyrighted by TeamMak Pty Ltd. All
            rights are reserved. None of the material may be copied, downloaded,
            displayed, posted, transmitted used or reproduced in whole or part
            in any form or by any means for any purpose unless such use is
            expressly permitted in writing by us or by applicable copyright
            laws. TeamMak gives approval to IBOs affiliated with TeamMak and who
            are subscribers to the TeamMak website and/or the TeamMak App to
            print and utilise information provided on this website and app for
            non-commercial use. Any information reproduced on this website and
            app cannot be altered or modified in any way; all copyright
            information must be reproduced with the document(s).
          </p>
          <h3>Website and App Owners</h3>
          <p>
            This website and app is owned by TeamMak Pty Ltd. It consists of
            information provided to TeamMak by both subscribers and also by
            TeamMak itself. TeamMak does not guarantee or monitor the
            information provided on this website or the app. All questions
            regarding information supplied by the website or the app, such as
            for events etc, should be directed to TeamMak or to the subscriber’s
            upline Diamond IBO. 
          </p>
          <h1>Subscriptions</h1>
          <h2>Website and App Subscriptions</h2>
          <p>
            Access to the TeamMak Website and TeamMak App (and its information,
            audios and video material) is available only via monthly
            subscription. A subscription once paid will provide access to the
            TeamMak Website as well as to the TeamMak App. Subscriptions are
            paid monthly in advance. Payments will be processed on the last
            Monday of the month for the following month.{' '}
          </p>
          <h2>Pricing</h2>
          <p>Premium CEP Membership</p>
          <ul>
            <div>
              800+ Streaming Audios
              <li>5 New Monthly Audios</li>
              <li>20 Shareable Candidate links</li>
              <li>50 Streaming Videos</li>
              <li>Events Section</li>
              <li>Website &amp; App access</li>
            </div>
          </ul>
          <p>
            $ 55.00 incl GST per month** <br />
            <small>
              **Prices and membership benefits/inclusions are subject to change
              from time to time with reasonable notice.
            </small>
          </p>
          <h2>Cancellations</h2>
          <p>
            No refunds will be given for subscriptions. However, subscriptions
            may be cancelled at any time by requesting cancellation via the
            <a href="https://teammak.com/contact"> contact us</a> link. TeamMak
            will only accept a cancellation request via the website and app. All
            cancellations will take effect at end of the billing period. No
            refunds will be given for part months. You can see your subscription
            expiration date on{' '}
            <a href="https://teammak.com/manage-subscription">
              https://teammak.com/manage-subscription
            </a>
          </p>
          <h2>Returns &amp; Refunds Policy </h2>
          <h3>Audio &amp; Video Materials</h3>
          <p>
            Audio or video materials including downloaded/streamed audio or
            video that are available via the TeamMak website and TeamMak App may
            not be returned or refunded. Should technical difficulties arise in
            downloading or streaming audios or videos that have been made
            available via the website or app, TeamMak will use its best efforts
            to assist the subscriber to resolve the issue.
          </p>
          <h3>Books</h3>
          <p>Books purchased from TeamMak are returnable provided:</p>
          <ol>
            <li>They have been purchased within the last 90 days;</li>
            <li>
              Proof of purchase will be required at the time of request for
              refund such as the purchaser's IBO number, date of purchase, and
              tax invoice or receipt;
            </li>
            <li>
              Postage/shipping for return of book(s) to TeamMak is the
              responsibility of the purchaser;
            </li>
            <li>The book must be in a resaleable “as new” condition.</li>
          </ol>
          <small>
            Please note that returned items will also be subject to a restocking
            fee of $5.00 incl GST per item.
          </small>

          <h3>Meeting &amp; Conference Registration/Tickets</h3>
          <p>The returns/refund policy is shown in a separate section below.</p>

          <h3>Other TeamMak Items.</h3>
          <p>
            On occasions other materials may be offered for sale by TeamMak.
            Please refer to the specific conditions of sale including
            returns/refund policy website and applicable to those items at the
            time of purchase.
          </p>

          <h2>
            Meeting, Seminar &amp; Conference/Event Registration or Tickets
          </h2>
          <p>
            From time to time TeamMak offers the opportunity for IBOs associated
            with TeamMak to attend meetings, seminars, conferences/events etc.
            Attendance by IBOs at any meetings, seminars, conferences/events etc
            is entirely at the choice of the IBO and is completely optional.
            Registration fees or tickets are generally only fully refundable up
            to 60 days prior to the date of the meeting, seminar, or
            conference/event etc. After this time registration fees/tickets are
            non-refundable unless medical or family bereavement situations
            apply. A medical certificate or statutory declaration will need to
            be provided to support refund requests in such circumstances. Please
            note that an administration fee of $50.00 incl GST per cancelled
            registration/ticket will be charged and deducted from the total
            amount refunded. <br /> See also{' '}
            <b>Registration/Ticketing Terms &amp; Conditions</b> for further
            information relating to the particular terms and conditions relating
            to meetings, seminars, conferences/events etc -
            <a
              onClick={() => {
                this.ticketingTermsAndConditionsRef.current?.scrollIntoView({
                  behavior: 'smooth',
                });
              }}
              href="#ticketing"
              className="t-secondary t-bold"
            >
              {' '}
              click here
            </a>
            .
          </p>

          <h3>Transferring Your Ticket Cost to Another Event.</h3>
          <p>
            In the event that you are eligible for a refund as above, we
            recommend that you consider transferring the registration/ticket
            value to a future meeting, seminar, or conference/event. However
            please note that this can only be done once. If you cannot attend
            the next event that the registration/ticket was rolled over to, the
            registration/ticket cost will not be refunded.
          </p>

          <h2 id="ticketing" ref={this.ticketingTermsAndConditionsRef}>
            Terms and Conditions of Registration and Attendance
          </h2>
          <p>
            The following terms and conditions apply to Your registration for,
            admission to, and continued attendance at the event to which You
            wish to register Your attendance (the "<b>Event</b>""), and to any
            of Your accompanying guests:
          </p>
          <h3>
            1. These terms and conditions of registration form a contract:
          </h3>
          <p>
            These terms and conditions of registration form a binding agreement
            between <b>Teammak Pty Ltd</b> (ABN 19 104 429 905) (“<b>We</b>”, “
            <b>Us</b>”, “<b>Our</b>”) and you, subject to clause 2 ("<b>You</b>
            ", "<b>Your</b>". "<b>Yourself</b>").
          </p>
          <h3>2. Your capacity and Relevant Persons:</h3>
          <ol type="a">
            <li>
              You enter into this agreement in Your own personal capacity, and
              also for and on behalf of:
              <ol>
                <li>
                  any business or organisation which You represent or which
                  employs You; and
                </li>
                <li>
                  any guest or other person(s) for whom You complete
                  registration for the Event, (each of which is a "
                  <b>Relevant Person</b>").
                </li>
              </ol>
            </li>
            <li>
              A reference to "You" in this agreement includes a reference to
              each Relevant Person, unless the context requires otherwise, and
              You must ensure that each Relevant Person complies with this
              agreement.
            </li>
            <li>
              You warrant that You:
              <ol>
                <li>
                  have completed the application for registration (the "
                  <b>Registration Form</b>", to which this agreement is
                  attached/linked accurately and truthfully;
                </li>
                <li>
                  have the express authority of all Relevant Persons to enter
                  into this agreement on its/their behalf;
                </li>
                <li>
                  have provided a copy of this agreement to all Relevant
                  Persons; and
                </li>
                <li>
                  have the consent of each Relevant Person (being an individual)
                  to provide his/her personal information to Us and for Us to
                  disclose it to the third parties.
                </li>
              </ol>
            </li>
            <li>
              If You register a Relevant Person (or for and on behalf of a
              Relevant Person) for this Event, You must ensure that each
              Relevant Person complies with these terms and conditions of
              registration and attendance.
            </li>
            <li>
              If You are a guest of a registrant for the Event or other Relevant
              Persons, it is a condition of Your admission to and continued
              attendance at the Event, that You agree to these terms and
              conditions of registration and attendance and that You comply with
              them. If You do not agree to these terms and conditions of
              registration and attendance, please notify Us and Your name will
              be removed from the attendance-list.
            </li>
            <li>
              To maximise the enjoyment and benefit of the programme for all, to
              minimise distractions to those around You, and to reduce the
              health and safety liability arising from possibly unsupervised
              young children, We request that You refrain from bringing Your
              children to any part of the Event. We do not guarantee or promise
              that changing or feeding facilities will be provided at any venue.
            </li>
          </ol>
          <h3>3. Compliance with venue terms and conditions:</h3>
          <p>
            You must at all times comply with the terms and conditions of entry
            to each venue connected with this Event, and with any lawful
            directions given by its authorised personnel.
          </p>
          <h3>4. Full payment required:</h3>
          <p>
            Full payment, in cleared funds and in Australian dollars, of the
            registration fee, applicable GST, and all other monies due and
            payable in connection with Your attendance and that of each Relevant
            Person at this Event must be made in accordance with the
            requirements, including as to timing, set out in the Registration
            Form and/or any registration "app" for the Event website.
          </p>
          <p>
            You and any Relevant Person will be denied entry to the Event if the
            attendance fee is not paid in full, by the due date or if You do not
            provide proof of identity and registration on entry. Subject to
            proof of identity and purchase of the ticket from Us, lost tickets
            will incur an administration fee of AUD$55.00 (including GST) for
            replacement.
          </p>
          <p>
            All persons, regardless of age (including children other than
            nursing infants), will require a ticket or equivalent to enter an
            auditorium, unless We expressly authorise otherwise (such as staff,
            VIPs, speakers etc).
          </p>
          <h3>5. Your cancellation of Your registration:</h3>
          <ol type="a">
            <li>
              IBOs in first 90 days: If You (or a business/organisation You
              represent) has, within the period of 90 days before Your
              acceptance of these terms and conditions, registered as an
              "Independent Business Owner" with Amway, You may cancel Your
              registration and We will refund all monies paid by You in full,
              provided that You:
              <ol>
                <li>
                  notify Us in writing of cancellation no later than 5pm on the
                  date that is 30 days prior to the Event commencement date;
                </li>
                <li>return Your admission ticket to Us at Your cost; and</li>
                <li>
                  purchased Your admission ticket for Your own use, directly
                  from Us provide written proof of purchase when You notify Us
                  of cancellation.
                </li>
              </ol>
            </li>
            <li>
              In all other cases: If paragraph (a) of this clause does not apply
              to You, You may cancel Your conference registration, by notifying
              Us by email. If You do not receive acknowledgement by email within
              two business days, please contact Us on the phone number noted on
              the Event website, or the registration "app" for the Event, as the
              case may be. Your entitlement to a refund or partial refund are as
              follows:
              <ol>
                <li>
                  If You cannot attend because it is unlawful (eg, covid-19 or
                  other Transmissible Illness): If You are unable, or will, with
                  certainty, be unable, to attend this Event for the reason that
                  it is unlawful for You to travel to the venue or would be
                  legally required to submit to quarantine or "lock-down", You
                  may notify Us at any time before the commencement of the Event
                  and provide particulars of the reason You cannot attend. If
                  We, acting reasonably, accept the reasons given, We will
                  refund all monies actually received to date; or
                </li>
                <li>
                  Your cancellation – for other reasons: If You wish to cancel
                  Your attendance at the Event for any reasons other than those
                  noted in sub-paragraph (i), You may notify Us by email, and if
                  We receive Your email before 5pm on the day that is 60 clear
                  days before the Event commences, We will refund all monies
                  actually received to date, less an administration fee of
                  AUD$55.00 (including GST).
                </li>
              </ol>
            </li>
            <li>
              In all cases: In all cases You may cancel Your registration after
              5pm on the date that is 60 days before the Event commences, but
              You will not be entitled to a refund of any kind, unless We agree
              otherwise, in Our discretion. We may, in Our discretion, give
              refunds only if You purchased Your registration/ticket for Your
              own use and directly from Us, and provide proof of such purchase,
              and of any circumstances that led to Your cancellation. Any refund
              under this paragraph is subject to an administration fee of
              AUD$55.00 (including GST).
            </li>
          </ol>
          <h3>6. Cancellation, postponement, conversion of Event by Us:</h3>
          <p>
            We may cancel, postpone or convert the Event to a partly or fully
            virtual (on-line) experience at any time and for any reason. We will
            notify You by email if any of the foregoing occurs, and:
          </p>
          <ol type="a">
            <li>
              Event cancellation: if the Event is cancelled, We will refund all
              monies paid to date;
            </li>
            <li>
              Event postponement: if the Event is postponed:
              <ol>
                <li>
                  You must notify Us, within the period We specify, if You wish
                  to cancel Your registration, whereupon You will receive a
                  refund of all monies paid to date. If You fail to notify Us of
                  Your cancellation within the period We specify, Your
                  registration will be cancelled unless We otherwise agree in
                  writing, and We will refund all monies paid to date; or
                </li>
                <li>
                  if You notify Us that You wish to attend on the new date, We
                  will retain the monies You have paid as a credit against Your
                  registration fee for the Event as postponed; and
                </li>
              </ol>
            </li>
            <li>
              Conversion to virtual Event: If the Event is converted to an
              on-line/virtual Event, You may:
              <ol>
                <li>
                  cancel Your registration by emailing Us within the period We
                  specify in Our notice to You and We will refund all monies
                  paid to date. If You do not notify Us of Your cancellation
                  within that period, Your registration will be deemed to be
                  cancelled unless We otherwise agree in writing, and We will
                  refund all monies paid to date; or
                </li>
                <li>
                  attend the Event on-line subject to clause 7, but in that
                  case, We will not refund monies paid to Us.
                </li>
              </ol>
            </li>
          </ol>
          <h3>7. Attendance of virtual (on-line) Event:</h3>
          <p>
            If participating in this Event, or any part of it, by virtual
            (on-line) means, You agree that:
          </p>
          <ol type="a">
            <li>
              it is Your responsibility to ensure that You have appropriate
              technology, internet connectivity and speed to participate;
            </li>
            <li>
              We are not liable for any interruption, delay sound or picture
              quality problems You may experience because Your technology is
              inadequate or because of internet or power interruptions, poor
              internet speed, or other connectivity problems You may experience;
            </li>
            <li>
              You are not entitled to a refund if You are unable to participate,
              or if the quality of Your participation is diminished, because of
              the matters referred to in paragraphs (a) and (b) of this clause;
            </li>
            <li>
              You will comply in all respects with clause 10 of this agreement
              and with any rules, protocols or other directions made by Us or
              the virtual session leader, whether published or issued in writing
              or orally; and
            </li>
            <li>
              if/when You post questions or messages to others participating by
              virtual means, Your personal information may be shared with those
              persons.
            </li>
          </ol>
          <h3>8. Assignment (transfer) of Your registration:</h3>
          <p>
            If We provide written consent, You may transfer Your registration
            for this Event to a person (the “<b>Transferee</b>”), provided that
            the Transferee agrees to be bound by these terms and conditions in
            such manner as We require. If a transfer of a registration is made
            under this clause, You must provide Us with the name and contact
            details of the transferee, pay an administration fee of AUD$55.00
            (including GST) and no refund of registration fees will be payable.
          </p>
          <h3>9. Event subject to change:</h3>
          <p>
            While We will make reasonable endeavours to stage the Event as
            advertised, You agree that the following may occur without liability
            on Our part of any kind to You (subject only to clause 6):
          </p>
          <ol type="a">
            <li>
              changes to the Event dates, the venue or the location of the Event
              or components of the Event within the venue;
            </li>
            <li>changes to programme content, its order or session times;</li>
            <li>
              changes to the speakers, exhibitors, entertainers and other
              presenters;
            </li>
            <li>
              changes to any social programme and the venue(s) for dinners or
              other social events.
            </li>
          </ol>
          <p>
            If any changes occur to the Event as advertised, reasonable
            endeavours will be made to arrange for reasonable substitutes,
            subject to availability and the circumstances that made the
            change(s) necessary or desirable. Notice of substantial and material
            changes will be placed on the Event Website. You are responsible for
            checking for such notifications/alerts prior to the Event.
          </p>
          <h3>10. Your conduct at this Event:</h3>
          <p>At all times during this Event, You must:</p>
          <ol type="a">
            <li>
              conduct Yourself in a reasonable, respectful, considerate and
              lawful manner and comply with all applicable laws;
            </li>
            <li>
              not act, speak or otherwise communicate in a manner that is
              offensive, obscene or that reasonable persons may consider to be
              offensive or obscene;
            </li>
            <li>
              not consume, be in possession of alcohol or unlawful drugs or
              other unlawful substances at any Event venue;
            </li>
            <li>
              not place the safety and health of any person(s) at the Event at
              risk and comply with clause 18 (COVID-19 and other Transmissible
              Illness - Safety);
            </li>
            <li>
              not cause personal injury to, or defame, any person or damage the
              property of any person;
            </li>
            <li>
              be respectful towards speakers and others expressing their
              opinions and refrain from causing a nuisance or interrupting or
              disrupting programme content; and
            </li>
            <li>
              comply promptly with Our reasonable and lawful directions and
              those of the authorised staff of the relevant venues.
            </li>
          </ol>
          <p>
            We may, request You (and/or a Relevant Person) to exit from the
            Event and/or refuse entry to the Event or any part of the Event if
            We, acting reasonably, consider that You or a Relevant Person are/is
            in breach of these terms and conditions, or for any other reasonable
            cause. If We exercise Our rights under this clause, You are not
            entitled to a refund of any kind and requesting Your exit is not Our
            exclusive remedy and We have no liability to You or any Relevant
            Person.
          </p>
          <h3>11. Limitation and exclusion of Our liability:</h3>
          <p>To the extent permitted by law:</p>
          <ol type="a">
            <li>
              to the extent permissible under the law, any claim that You and/or
              a Relevant Person (and anyone claiming through You or a Relevant
              Person) may have in respect of goods or services supplied in
              connection with the Event, which claim is pursuant to a statutory
              right that cannot be excluded, Our liability is limited, in the
              case of:
              <ol>
                <li>
                  goods, to the replacement of the goods or the cost of having
                  the goods replaced or repaired; and
                </li>
                <li>
                  services, to the resupply of the services or the cost of
                  having the services resupplied;
                </li>
              </ol>
            </li>
            <li>
              Our liability to You and any Relevant Person for any other cause
              of action, including but not limited to breach of contract,
              negligence or other breach of duty:
              <ol>
                <li>
                  for travel and accommodation expenses is
                  <b>excluded in full</b>. Expenses for travel and accommodation
                  are at Your sole risk. We recommend that You consider making
                  accommodation and transport arrangements that permit variation
                  or cancellation with appropriate refunds. We further recommend
                  that You obtain insurance (if available) that will reimburse
                  Your accommodation and transport costs in the event of
                  cancellation, postponement or conversion of the Event to a
                  virtual event;
                </li>
                <li>
                  loss of enjoyment, loss of profit, loss of revenue, lost cost
                  savings, loss of opportunity, loss of enjoyment, damage to
                  reputation or indirect or consequential loss of any kind, is
                  <b>excluded in full</b>;
                </li>
                <li>
                  infection with COVID-19 or any variant or illness evolved, or
                  that develops, from COVID-19, is excluded, except to the
                  extent that such loss is directly caused by Our breach of
                  applicable laws, but always provided that Our liability in any
                  such case is limited as provided in paragraph (iv) of this
                  clause; and
                </li>
                <li>
                  for any other loss, including but not limited to personal
                  injury or death, damage to property or any business,
                  <b>is limited</b> in aggregate to the sum of the monies
                  actually paid by You in connection with Your registration for
                  the Event.
                </li>
              </ol>
            </li>
          </ol>
          <h3>12. No promise of success, etc:</h3>
          <p>
            If the Event includes any presentation concerning techniques,
            methods or approaches to the management or operation of business, We
            make no warranty or other promise that the adoption of such
            techniques, methods or approaches will generate profit or success of
            any kind, however You may measure it. While such techniques, methods
            and approaches have assisted others (and We hope they will assist
            You), they may not work for You. You must not rely on the content of
            such presentations as professional, financial or other advice to You
            or Your business.
          </p>
          <h3>13. Suppliers are independent contractors:</h3>
          <p>You acknowledge and agree that:</p>
          <ol type="a">
            <li>
              all goods and services supplied to You in connection with the
              Event (including but not limited to transport, accommodation, the
              venue, food and beverage, audio-visual services, security,
              cleaning, programme content and entertainment) will be supplied by
              suppliers (each, a “<b>Supplier</b>”) who are all Our independent
              contractors;
            </li>
            <li>
              no Supplier, attendee, speaker, exhibitor, or sponsor is Our
              employee, agent or partner and We are not vicariously or jointly
              liable for their actions or omissions;
            </li>
            <li>
              nothing in this agreement excludes or "contracts-out" of any
              applicable laws providing for proportionate liability; and
            </li>
            <li>
              the views and opinions expressed by any event attendee, speaker,
              exhibitor, or sponsor are not necessarily Our views or opinions.
              All attendees, speakers, exhibitors, and sponsors are solely
              responsible for the content of their presentations, marketing
              collateral, advertising and their actions and omissions.
            </li>
          </ol>
          <h3>
            14. Your breach may cause Us to have liability to third parties:
          </h3>
          <p>You acknowledge and agree that:</p>
          <ol type="a">
            <li>
              Your negligence or breach of this agreement or of the venue’s
              terms and conditions of entry may cause Us to be in breach of
              contractual obligations (including indemnities) or other duties or
              obligations that We may have to the venue operator or other third
              parties, such as other attendees or other participants in the
              Event; and
            </li>
            <li>
              any loss or liability We incur to the venue operator or any other
              third party, as a direct or indirect consequence of Your act or
              omission, whether or not We have assumed such liability
              contractually, is a reasonably foreseeable and proximate loss that
              We can recover from You.
            </li>
          </ol>
          <h3>15. Indemnity:</h3>
          <ol type="a">
            <li>
              You must indemnify and keep Us indemnified against all losses,
              costs and expenses (including actual legal costs and disbursements
              on a full indemnity basis) incurred in connection with any claim,
              demand, action or proceedings arising wholly or partly, directly
              or indirectly, from Your act or omission, and whether such claim,
              demand, action or proceeding is founded wholly or partly on:
              <ol>
                <li>
                  Your unlawful act or omission, negligence or other tort,
                  breach of contract (including this agreement), or any other
                  breach of duty or cause of action whatsoever; and/or
                </li>
                <li>
                  any term of a contract between Us and a third party by which
                  We are made liable for Your act or omission.
                </li>
              </ol>
            </li>
            <li>
              You acknowledge and agree that the indemnity in paragraph (a) of
              this clause is not Our exclusive remedy.
            </li>
          </ol>
          <h3>16. Photography and videography/filming:</h3>
          <ol type="a">
            <li>
              You must not take any photograph or record video or audio/sounds
              of any presentation at the Event by any means, unless We expressly
              permit this. If You are discovered doing so, We may exercise Our
              rights to request that You exit the Event as provided in clause
              10.
            </li>
            <li>
              We may hire service providers (photo/video/streaming/audio) to
              document and display the Event experience. We may also use social
              media to post photos and videos and to display select submissions
              at the venue and on Our websites.
            </li>
            <li>
              You and all Relevant Persons irrevocably authorise Us to:
              <ol>
                <li>
                  record You and each Relevant Person (picture and voice) on
                  photos, films and sound recordings (each, a “Recording”); and
                </li>
                <li>
                  edit and incorporate the Recording into a photo, video
                  gallery, short film or webinar as a record of and for the
                  promotion of the Event and future similar events, for no
                  monetary or other compensation. You and each Relevant Person
                  agree that You/they have no right, title or interest in
                  Recordings.
                </li>
              </ol>
            </li>
          </ol>
          <h3>17. Privacy and electronic messages:</h3>
          <p>You consent to:</p>
          <ol type="a">
            <li>
              Our collection, use and disclosure of Your personal information,
              including sensitive information as defined in the Privacy Act 1998
              (Cwlth);
            </li>
            <li>
              Our disclosure of Your personal information to attendees and other
              participants in the Event, and to Your receipt of electronic
              messages concerning them and their products and services,
            </li>
            <li>
              the disclosure of "sensitive" personal information to Suppliers
              (such as health information), to the extent We consider it
              necessary or desirable to enable them to provide appropriate goods
              or services to You;
            </li>
            <li>
              Our sending You electronic messages concerning the Event and any
              other event that We reasonably consider You may be interested to
              attend.
            </li>
          </ol>
          <h3>18. COVID-19 and other Transmissible Illness - safety:</h3>
          <ol type="a">
            <li>
              We may require that You give Us evidence of vaccination in
              connection with or any transmissible illness, including but not
              limited to COVID-19 ("Transmissible Illness") as a pre-condition
              to allowing Your attendance at the Event. Details of such
              requirements will be notified on the Event Website and/or emailed
              to You.
            </li>
            <li>
              You must not attend the Event if:
              <ol>
                <li>
                  You have been diagnosed as having a Transmissible Illness, are
                  feeling unwell with symptoms that may indicate infection with
                  a Transmissible Illness; or
                </li>
                <li>
                  You are prohibited from travelling to or attending the Event
                  by applicable laws or prevailing health orders, concerning
                  Transmissible Illness.
                </li>
              </ol>
            </li>
            <li>
              At entry to and while attending the Event, You must:
              <ol>
                <li>
                  comply with all applicable laws and health orders concerning
                  Transmissible Illness;
                </li>
                <li>
                  comply with all directions issued by Us or venue staff and
                  with safety protocols such as social distancing and hand
                  sanitising;
                </li>
                <li>
                  comply with and submit to contact tracing protocols and
                  procedures for Transmissible Illness safety, including but not
                  limited to, as We may require at Our option, providing Us with
                  evidence of Your vaccination status, registering with a QR
                  Code on entry, and if requested, You must submit to
                  temperature testing; and
                </li>
                <li>
                  if You experience any symptoms that may indicate infection
                  with Transmissible Illness, You must immediately report to
                  Event staff and follow all reasonable directions given by
                  Event staff or health professionals present. If requested to
                  leave the venue, You must comply with that request.
                </li>
              </ol>
            </li>
          </ol>
          <h3>19. Frustration of contract statutes not to apply:</h3>
          <p>
            The parties agree that the sole rights and consequences of
            cancellation and variation of the Event and of Your registration are
            set out in this agreement. The Australian Consumer Law and
            Fair-Trading Act 2012 (Vic), the Frustrated Contracts Act 1978 (NSW)
            and other legislation (in any other jurisdiction) concerning
            frustration of contract, do not apply to this agreement.
          </p>
          <h3>20. Governing law:</h3>
          <p>
            These terms and conditions are made under and governed by the laws
            applicable in Victoria, Australia, and You accept the non-exclusive
            jurisdiction of the courts of that place.
          </p>
          <h3>21. Notices:</h3>
          <p>
            Notices to You must be sent to the email address specified in the
            registration form You submit to Us. Notices to Us must be sent to Us
            at the email address noted on the Event website or the registration
            "app" for the Event. A notice is deemed to have been received on the
            date of the email unless the same shall be outside the hours of 9am
            to 5pm on business days in the place of receipt, in which case such
            notice will be deemed to have been served on the following business
            day at that place.
          </p>

          <h2>TeamMak Website and TeamMak App Acceptable Use Policy</h2>
          <p>
            TeamMak will not tolerate any user who submits offensive, abusive or
            illegal material (i.e. pornography, pirated software, hate mail
            etc). TeamMak routinely run checks to ensure that this policy is
            adhered to. If you are unsure what constitutes offensive, abusive or
            illegal material please contact TeamMak -
            <a className="t-secondary t-bold" href="mailto:admin@teammak.com">
              click here
            </a>
            .
          </p>

          <h2>Privacy Policy</h2>
          <p>
            By registering as a subscriber and/or by purchasing a
            registration/ticket for a meeting, seminar, or conference/event, an
            IBOs' relevant IBO number and contact details will be added to a
            database for the purposes of:
          </p>

          <ul>
            <li>administering both the TeamMak Website and TeamMak App;</li>
            <li>
              updating subscribers regarding meetings; seminars, or
              conferences/events;
            </li>
            <li>
              confirming attendance at meetings, seminars, or
              conferences/events;
            </li>
            <li>and updating IBOs on other business-related subjects.</li>
          </ul>

          <p>
            The database will be maintained by TeamMak and will not be made
            available to any third parties unless those third parties are
            authorised and acting on behalf of, or as agents of, TeamMak. Please
            click here to view the details of the
            <Link className="t-secondary t-bold" to="/privacy-policy">
              <span> TeamMak Privacy Policy</span>.
            </Link>
          </p>
        </div>
      </section>
    );
  }
}
